import { GatsbySeo } from 'gatsby-plugin-next-seo'

import FitnessCardio from '../../components/Campanha/FitnessCardio'

function ColecaoFitness2022() {
  return (
    <>
      <GatsbySeo title="Campanha | Decathlon" noindex nofollow />
      <FitnessCardio />
    </>
  )
}

export default ColecaoFitness2022
